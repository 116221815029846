import React, {useEffect} from 'react';

export const DashboardHeader = React.memo(() => {
    useEffect(() => {
        document.title = 'WaterGraph Admin';

        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon instanceof HTMLLinkElement) {
            console.log("123123123")

            favicon.href = '/administrator.png';
        } else {

            const link = document.createElement('link');
            link.rel = 'icon';
            link.href = '/client-favicon.ico';
            document.head.appendChild(link);
        }

    }, []);
  return (
    <header
      id="dashboard-header"
      className="flex w-screen flex-col justify-center border-b border-solid border-blue-400 bg-slate-50 text-sky-700">
      <span
        className="my-auto ml-3 whitespace-nowrap text-4xl font-semibold"
        style={{
          font: 'Noto Sans',
        }}>
        WATERGRAPH管理
      </span>
    </header>
  );
});
