import { useAuth } from 'src/lib/auth/use-auth';
import { Leaflet } from '../components/Leaftlet.component';
import {useEffect} from "react";

export function MapPage() {
  const { user } = useAuth();

    useEffect(() => {
        document.title = 'WaterGraph Map';

        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon instanceof HTMLLinkElement ) {
            favicon.href = '/map.png';
        } else {
            const link = document.createElement('link');
            link.rel = 'icon';
            link.href = '/favicon.ico';
            document.head.appendChild(link);
        }

    }, []);

  return (
    <Leaflet
      mapContainerProps={{
        center: [37.5, 138],
        zoom: 6,
        minZoom: 5,
        style: { height: '100vh' },
      }}
    />
  );
}
