import dayjs from 'dayjs';
import { LatLng, Map, Marker as MarkerEntity, TileLayer as TileLayerEntity } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { Datepicker } from 'src/lib/components/DatePicker.component';
import { Select } from 'src/lib/components/Select.component';
import { TextArea } from 'src/lib/components/TextArea.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import {fdelete, fget, fput, getUser} from 'src/lib/fetch';
import { SelectBoxBaseLayer } from 'src/modules/map-layers/components/SelectBoxBaseLayer.component';
import { MarkerMapControl } from 'src/modules/map-markers/components/MarkerMapControl.component';
import { BASE_MAP_URL } from 'src/redux/slices/baseLayer.slice';
import { AreaEntity } from '../../dto/area/area.entity';
import { DeviceEntity } from '../../dto/device/device.entity';
import { DeleteDeviceReq, UpdateDeviceReq } from '../../dto/device/device.req';
import { ResponseDocument, ResponseGetDocument, ResponseListDocuments } from '../../dto/response';
import { RiverEntity } from '../../dto/river/river.entity';
import {RadioGroup} from "../../../../lib/components/RadioAdmin.component";
import {dataRow, HistoryTableComponent} from "../../../../lib/components/historyTable.component";

export function EditDevicePage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextName = useRef<HTMLInputElement>(null);
  const eTextNumber = useRef<HTMLInputElement>(null);
  const eTextType = useRef<HTMLSelectElement>(null);
  const eTextCoordinateX = useRef<HTMLInputElement>(null);
  const eTextCoordinateY = useRef<HTMLInputElement>(null);
  const eSelectRiver = useRef<HTMLSelectElement>(null);
  const eSelectArea = useRef<HTMLSelectElement>(null);
  const eTextWaterLevelFormula = useRef<HTMLInputElement>(null);
  const eTextFlowRateFormula = useRef<HTMLInputElement>(null);
  const eTextVelocityFormula = useRef<HTMLInputElement>(null);
  const eTextCrossSectionFormula = useRef<HTMLInputElement>(null);
  const eTextWaterLevelThreshold = useRef<HTMLInputElement>(null);
  const eTextAlertReceiptEmail = useRef<HTMLInputElement>(null);
  const eTextVelocityThreshold = useRef<HTMLInputElement>(null);
  const eTextWaterFlowThreshold = useRef<HTMLInputElement>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
  };
  const [dataHistory, setDataHistory] = useState<dataRow[]>([]);

    const eButtonSubmit = useRef<HTMLButtonElement>(null);
  const eButtonDelete = useRef<HTMLButtonElement>(null);

  const baseTileLayer = useRef<TileLayerEntity>(new TileLayerEntity(''));
  const map = useRef<Map>(null);
  const eMarker = useRef<MarkerEntity>(null);

  const eButtonModeEdit = useRef<HTMLInputElement>(null);
  const [checkedModeEdit, setCheckedModeEdit] = useState<boolean>(false);

  const { id } = useParams();
  const [data, setData] = useState<{
    device: ResponseDocument<DeviceEntity>;
    areas: ResponseDocument<AreaEntity>[];
    rivers: ResponseDocument<RiverEntity>[];
  }>();


  const navigate = useNavigate();

  const onChangeArea = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      const res = await fget(`api/riverByArea?AREA_NAME=${e.target.value}`, {});

      const rivers = res?.[1]?.Items;

      if (!rivers) throw new Error('Error fetching data');

      setData((prev: any) => {
        return {
          ...prev,
          rivers,
        };
      });
    } catch (e) {
      console.error(e);
      toasting({ children: 'データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
    }
  };

  const installDate = useRef<string | undefined>(undefined);
  const removeDate = useRef<string | undefined>(undefined);

  const onChangeInstallDate = (date: Date | null | undefined) => {
    installDate.current = date?.getTime() ? (+date.getTime() / 1000).toString() : undefined;
  };

  const onChangeRemoveDate = (date: Date | null | undefined) => {
    removeDate.current = date?.getTime() ? (+date.getTime() / 1000).toString() : undefined;
  };

  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!eForm.current?.checkValidity()) {
      return eForm.current?.reportValidity();
    }

    try {
      await fput<UpdateDeviceReq>(`api/devices/${id}`, {
        body: {
          device: {
            DEVICE_ID: data?.device?.DEVICE_ID.S,
            DEVICE_NAME: eTextName.current?.value || '',
            DEVICE_NUMBER: eTextNumber.current?.value || '',
            DEVICE_TYPE: eTextType.current?.value || '',
            DEVICE_COORDINATE: `${eTextCoordinateX.current?.value || ''},${eTextCoordinateY.current?.value || ''}`,
            RIVER_NAME: eSelectRiver.current?.value || '',
            AREA_NAME: eSelectArea.current?.value || '',
            INSTALL_DATE: installDate?.current || '',
            REMOVE_DATE: removeDate?.current || '',
            CONNECTED_DEVICE_IDS: data?.device?.CONNECTED_DEVICE_IDS?.L?.map((v) => v?.S) || [],
            POSITIONS: data?.device?.POSITIONS?.S,
            WATER_LEVEL_THRESHOLD: eTextWaterLevelThreshold.current?.value || '',
            ALERT_RECEIPT_EMAIL: eTextAlertReceiptEmail.current?.value || '',
            VELOCITY_THRESHOLD: eTextVelocityThreshold.current?.value || '',
            WATER_FLOW_THRESHOLD: eTextWaterFlowThreshold.current?.value || '',
            DEVICE_STATUS: selectedStatus || 'ACTIVE',
            WATER_LEVEL_FORMULA: eTextWaterLevelFormula.current?.value || '',
            VELOCITY_FORMULA: eTextVelocityFormula.current?.value || '',
            FLOW_RATE_FORMULA: eTextFlowRateFormula.current?.value || '',
            CROSS_SECTION_FORMULA: eTextCrossSectionFormula.current?.value || '',
          },
        },
      });

      navigate('..');
    } catch (error) {
      console.error('Error creating device:', error);
      toasting({ children: 'デバイスの編集に失敗しました。', containerProps: { className: 'border-red-600' } });
    }
  };

  const handleButtonDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!eForm.current?.checkValidity()) {
      return eForm.current?.reportValidity();
    }

    const isConfirmed = window.confirm('本当に削除しますか？');
    if (!isConfirmed) return;

    try {
      await fdelete<DeleteDeviceReq>(`api/devices/${id}`);

      navigate('..');
    } catch (error) {
      console.error('Error deleting device:', error);
      toasting({ children: 'デバイスの削除に失敗しました。', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [areaResponse, deviceResponse] = await Promise.all([
          fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas'),
          fget<unknown, ResponseGetDocument<DeviceEntity>>(`api/devices/${id}`),
        ]);
        const device = deviceResponse?.[1]?.Item;

        if (!device) throw new Error('Error fetching data');

        const areaName = device?.AREA_NAME?.S;

        const riverResponse = await fget<unknown, ResponseListDocuments<RiverEntity>>(
          `api/riverByArea?AREA_NAME=${areaName}`,
        );

        const areas = areaResponse?.[1]?.Items;
        const rivers = riverResponse?.[1]?.Items;

        if (!areas || !rivers) throw new Error('Error fetching data');

        installDate.current = device.INSTALL_DATE?.S;
        removeDate.current = device.REMOVE_DATE?.S;

        setData({ areas, rivers, device });
        setSelectedStatus(device.DEVICE_STATUS?.S ?? 'ACTIVE')
      } catch (error) {
        console.error('Error fetching data:', error);
        toasting({ children: 'データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
      }
    };

    fetchData();
  }, [id]);

    useEffect(() => {
        const fetchChangeHistory = async () => {
            try {
                const res = await fget<unknown, any>(`api/device-change-history?deviceId=${id}`);

                if (!res?.[1]?.changes) throw new Error('Error fetching change history');

                setDataHistory(res?.[1]?.changes); // Lưu dữ liệu lịch sử vào state
            } catch (error) {
                console.error('Error fetching change history:', error);
                toasting({ children: '履歴データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
            }
        };

        fetchChangeHistory();
    }, [id]);


    if (!data) return <></>;

    return (
    <form className="flex flex-col px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap  text-2xl">デバイス編集</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="mt-2 flex basis-full justify-end">
        <Checkbox
          className="hidden"
          onChange={(e) => setCheckedModeEdit(e.target.checked)}
          defaultChecked={checkedModeEdit}
          ref={eButtonModeEdit}
          containerProps={{
            className: `mt-4 ml-auto border border-gray-500`,
          }}
          labelProps={{
            children: '編集モード',
            className: `select-none p-2 ${checkedModeEdit ? 'bg-black text-white' : ''}`,
          }}
        />
      </div>

      <div className="mt-2 flex flex-nowrap gap-8">
        <div className="flex w-2/3 flex-row flex-wrap justify-between gap-8">
          <RadioGroup
            options={[
              { value: 'ACTIVE', label: 'アクティブ' },
              { value: 'INACTIVE', label: '非アクティブ' },
            ]}
            disabledProp={!checkedModeEdit}
            name="status"
            selectedValue={selectedStatus ?? (data?.device?.DEVICE_STATUS?.S ?? 'ACTIVE')}
            onChange={handleStatusChange}
            labelProps={{
              children: (
                <>
                  <div>デバイスのステータス</div>
                  <div className="text-[11px]">デバイスがアクティブまたは非アクティブであることを選択してください。</div>
                </>
              ),
              color: 'blue',
            }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
          />
          <TextInput
            labelProps={{
              children: (
                <>
                  <div>デバイス名</div>
                  <div className="text-[11px]">AWS の IoT Core のモノ (Thing) の名前と一致することが必要</div>
                </>
              ),
            }}
            type="text"
            defaultValue={data?.device.DEVICE_NAME.S}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            placeholder="device11"
            required
            ref={eTextName}
            disabled={!checkedModeEdit}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>デバイス番号</div>
                  <div className="text-[11px]">AWS に送信する GID と一致することが必要</div>
                </>
              ),
            }}
            type="text"
            defaultValue={data?.device?.DEVICE_NUMBER?.S}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            placeholder="1001"
            required
            ref={eTextNumber}
            disabled={!checkedModeEdit}
          />

          <Select
            defaultValue={data?.device?.DEVICE_TYPE?.S}
            labelProps={{ value: '種類' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            disabled={!checkedModeEdit}
            ref={eTextType}>
            <option value={'水位と流速セット'}>水位と流速セット</option>
            <option value={'水位のみ'}> 水位のみ</option>
          </Select>

          <div className="item flex basis-full flex-col">
            <label className="mb-2 text-sm font-normal text-gray-900 dark:text-white">設置座標</label>
            <div className="flex flex-row flex-wrap justify-between gap-4">
              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'X',
                }}
                type="text"
                defaultValue={data?.device.DEVICE_COORDINATE?.S?.split(',')?.[0]}
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                ref={eTextCoordinateX}
                disabled={!checkedModeEdit}
              />

              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'Y',
                }}
                type="text"
                defaultValue={data?.device.DEVICE_COORDINATE?.S?.split(',')?.[1]}
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                ref={eTextCoordinateY}
                disabled={!checkedModeEdit}
              />
            </div>
          </div>

          <Select
            defaultValue={data?.device.AREA_NAME?.S}
            labelProps={{ value: '地域名' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            required
            onChange={onChangeArea}
            ref={eSelectArea}
            disabled={!checkedModeEdit}>
            {data?.areas?.map((area, i) => (
              <option key={`Option-` + i} value={area.AREA_NAME.S}>
                {area.AREA_NAME.S}
              </option>
            ))}
          </Select>

          <Select
            labelProps={{ value: '川の名前' }}
            defaultValue={data?.device.RIVER_NAME.S}
            containerProps={{
              className: 'w-1/3 basis-full basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            required
            ref={eSelectRiver}
            disabled={!checkedModeEdit}>
            {data?.rivers?.map((river, i) => (
              <option key={`Option-` + i} value={river.RIVER_NAME.S}>
                {river.RIVER_NAME.S}
              </option>
            ))}
          </Select>

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>水位の計算式</div>
                  <div className="text-[11px]">水面までの距離を X とし計算式を入力ください</div>
                  <div className="text-[11px]">水位(m)の計算式</div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            defaultValue={data?.device.WATER_LEVEL_FORMULA?.S}
            ref={eTextWaterLevelFormula}
            disabled={!checkedModeEdit}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>断面積の計算式</div>
                  <div className="text-[11px]">水位をXとして計算式を入力してください</div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            placeholder="99 * X - 100"
            defaultValue={data?.device.CROSS_SECTION_FORMULA?.S}
            ref={eTextCrossSectionFormula}
            disabled={!checkedModeEdit}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>流速</div>
                  <div className="text-[11px]">
                    コンデンサ電圧を X とし、計算式を入力ください。CNT の値に基づいて条件分岐 (IF 文)
                    を使用することができます。例：IF(CNT=2 OR CNT=3, 6200-X, X)
                  </div>
                  <div className="text-[11px]">
                    流速(m/s)の計算式
                  </div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            defaultValue={data?.device.VELOCITY_FORMULA?.S}
            ref={eTextVelocityFormula}
            disabled={!checkedModeEdit}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>流量</div>
                  <div className="text-[11px]">
                    水位をX, 流速をY, 断面積をZとして計算式を入
                    力してください、計算式を入力ください。CNT の値に基づいて条件分岐
                    (IF 文) を使用することができます。例：IF(CNT=2 OR CNT=3, 6200-X+Y, X+Y)
                  </div>
                  <div className="text-[11px]">
                    流量(m3/s)の計算式
                  </div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            defaultValue={data?.device.FLOW_RATE_FORMULA?.S}
            ref={eTextFlowRateFormula}
            disabled={!checkedModeEdit}
          />

          <TextInput
              labelProps={{
                children: (
                    <>
                      <div>水位閾値</div>
                      <div className="text-[11px]">
                        水位が閾値を超えた場合、メールで通知します。
                      </div>
                    </>
                ),
              }}
              type="number"
              containerProps={{
                className: 'w-1/3 basis-full',
                style: {
                  minWidth: '12rem',
                },
              }}
              defaultValue={data?.device.WATER_LEVEL_THRESHOLD?.S}
              disabled={!checkedModeEdit}
              ref={eTextWaterLevelThreshold}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>流速閾値</div>
                  <div className="text-[11px]">
                    流速が閾値を超えた場合、メールで通知します。
                  </div>
                </>
              ),
            }}
            type="number"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            defaultValue={data?.device.VELOCITY_THRESHOLD?.S}
            disabled={!checkedModeEdit}
            ref={eTextVelocityThreshold}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>流量閾値</div>
                  <div className="text-[11px]">
                    流量が閾値を超えた場合、メールで通知します。
                  </div>
                </>
              ),
            }}
            type="number"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            defaultValue={data?.device.WATER_FLOW_THRESHOLD?.S}
            disabled={!checkedModeEdit}
            ref={eTextWaterFlowThreshold}
          />

          <TextInput
              labelProps={{
                children: (
                    <>
                      <div>通知先</div>
                      <div className="text-[11px]">
                        example: hung.pioner@pionero.io, nam.pionero@pionero.io, anh.pionero@pionero.io
                      </div>
                    </>
                ),
              }}
              type="text"
              containerProps={{
                className: 'w-1/3 basis-full',
                style: {
                  minWidth: '12rem',
                },
              }}
              defaultValue={data?.device.ALERT_RECEIPT_EMAIL?.S}
              disabled={!checkedModeEdit}
              ref={eTextAlertReceiptEmail}
          />

          <Datepicker
            labelProps={{
              children: '設置日時',
            }}
            messages={{
              moveToday: '今日',
            }}
            className="w-full"
            defaultValue={installDate?.current ? dayjs.unix(+installDate.current).toDate() : undefined}
            locale="ja"
            containerProps={{
              style: {
                minWidth: '12rem',
              },
            }}
            onChange={onChangeInstallDate}
            disabled={!checkedModeEdit}
          />

          <Datepicker
            labelProps={{
              children: '撤去・入れ替え日時',
            }}
            messages={{
              moveToday: '今日',
            }}
            className="ml-auto w-full"
            defaultValue={removeDate?.current ? dayjs.unix(+removeDate.current).toDate() : undefined}
            locale="ja"
            containerProps={{
              style: {
                minWidth: '12rem',
              },
            }}
            onChange={onChangeRemoveDate}
            disabled={!checkedModeEdit}
          />

          {!checkedModeEdit && (
            <TextArea
              labelProps={{
                value: '削除日の履歴',
              }}
              containerProps={{
                className: 'basis-full',
                style: {
                  minWidth: '5rem',
                },
              }}
              disabled={true}
              value={data?.device?.REMOVE_DATE_HISTORIES?.L?.map((item) => {
                if (!item.S) return '';
                return dayjs.unix(+item.S).format('YYYY/MM/DD HH:mm:ss');
              })
                .filter((v) => v)
                .join('\n')}
              className="custom-scrollbar-1 rounded-t-md"
            />
          )}

            {!checkedModeEdit && (
                <HistoryTableComponent
                    className=""
                    data={dataHistory}
                ></HistoryTableComponent>
            )}


          {checkedModeEdit && (
            <div className="block basis-full justify-center">
              <Button
                className="mx-auto rounded-md bg-black text-xs text-white"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonSubmit}
                ref={eButtonSubmit}>
                変更
              </Button>
              <Button
                className="mx-auto mt-6 rounded-md border bg-transparent text-xs text-orange-600"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonDelete}
                ref={eButtonDelete}>
                削除
              </Button>
            </div>
          )}

          <Link
            className="mx-auto rounded-md border border-gray-600 bg-transparent p-2.5 text-center text-xs"
            to=".."
            style={{
              minWidth: '256px',
            }}>
            {eButtonModeEdit.current?.checked ? 'キャンセル' : '戻る'}
          </Link>
        </div>

        <MapContainer
          attributionControl={false}
          minZoom={5}
          zoom={9}
          center={[33.9195, 133.1811]}
          className="mt-4"
          style={{
            height: '80vh',
            width: '20vw',
            minWidth: '48rem',
            border: '0.25rem solid',
            flexBasis: '100%',
          }}
          ref={map}>
          <TileLayer url={decodeURI(BASE_MAP_URL.std.href)} ref={baseTileLayer} maxNativeZoom={18} />
          <Control
            position="topright"
            container={{
              className: 'flex bg-none flex-col',
            }}>
            <SelectBoxBaseLayer baseTileLayer={baseTileLayer} />
          </Control>

          {data?.device && (
            <MarkerMapControl
              ref={eMarker}
              position={
                data.device.DEVICE_COORDINATE.S
                  ? new LatLng(
                      +data.device.DEVICE_COORDINATE.S.split(',')[0],
                      +data.device.DEVICE_COORDINATE.S.split(',')[1],
                    )
                  : undefined
              }
              onClickMap={(e) => {
                eButtonModeEdit.current?.checked &&
                  eTextCoordinateX.current &&
                  eTextCoordinateY.current &&
                  (eTextCoordinateX.current.value = `${e.latlng.lat}`) &&
                  (eTextCoordinateY.current.value = `${e.latlng.lng}`);

                return !!eButtonModeEdit.current?.checked;
              }}
            />
          )}
        </MapContainer>
      </div>
    </form>
  );
}
