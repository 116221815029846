import React from 'react';

export interface dataRow {
    deviceId: number,
    timeChange: string;
    oldValue: string;
    newValue: string;
    field: string;
    username: string;
    createdAt: string;
}
export const HistoryTableComponent = React.forwardRef<
    HTMLDivElement,
    {
        className?: string;        // Class tùy chỉnh
        data: dataRow[];           // Dữ liệu bảng
    }
>((props, ref) => {
    // Hàm nhóm dữ liệu theo `date`
    const groupByDate = (data: dataRow[]) => {
        const grouped: { [date: string]: dataRow[] } = {};
        data.forEach((row) => {
            if (!grouped[row.timeChange]) {
                grouped[row.timeChange] = [];
            }
            grouped[row.timeChange].push(row);
        });
        return grouped;
    };

    const downloadCSV = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        const groupedData = groupByDate(props.data);

        // Tiêu đề CSV
        const headers = ['日付', '番号', 'フィールド名', '旧値', '新値', 'ユーザー'];

        // Dòng dữ liệu CSV
        const csvRows = [headers];
        Object.keys(groupedData).forEach((date) => {
            const rows = groupedData[date];
            rows.forEach((row, index) => {
                csvRows.push([
                    index === 0 ? date : '', // Merge các hàng có cùng `datetime`
                    (index + 1).toString(), // Số thứ tự
                    row.field,
                    row.oldValue,
                    row.newValue,
                    row.username,
                ]);
            });
        });

        // Convert array to CSV string
        const csvContent = csvRows.map((row) => row.join(',')).join('\n');

        // Tải file CSV
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'device_change_history.csv';
        a.click();
        URL.revokeObjectURL(url);
    };



    // Nhóm dữ liệu
    const groupedData = groupByDate(props.data);
    return (
        <div>
            <div className="mb-2 block">
                <label className="text-sm font-medium text-gray-900 dark:text-gray-300"
                       data-testid="flowbite-label" htmlFor="TextArea-0">
                    デバイス変更履歴
                </label>

            </div>
            <button
                onClick={downloadCSV}
                className=" mt-2 mb-2 px-4 rounded-md py-2 border border-gray-600 bg-transparent p-2.5 text-center text-xs"
            >
                CSVダウンロード
            </button>

            <div
                ref={ref}
                className={`overflow-x-auto border border-gray-300 ${props.className || ""}`}
            >

                {/* Container giới hạn chiều cao và bật scroll */}
                <div className="max-h-[300px] overflow-y-auto">

                    <table
                        className="min-w-full border-collapse border border-gray-200 text-sm text-left text-gray-500">
                        {/* Header cố định */}
                        <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                        <tr>
                            <th
                                scope="col"
                                className="border px-4 py-2 sticky top-0 bg-gray-100"
                            >
                                日付
                            </th>
                            <th
                                scope="col"
                                className="border px-4 py-2 sticky top-0 bg-gray-100"
                            >
                                番号
                            </th>
                            <th
                                scope="col"
                                className="border px-4 py-2 sticky top-0 bg-gray-100"
                            >
                                フィールド名
                            </th>
                            <th
                                scope="col"
                                className="border px-4 py-2 sticky top-0 bg-gray-100"
                            >
                                旧値
                            </th>
                            <th
                                scope="col"
                                className="border px-4 py-2 sticky top-0 bg-gray-100"
                            >
                                新値
                            </th>
                            <th
                                scope="col"
                                className="border px-4 py-2 sticky top-0 bg-gray-100"
                            >
                                ユーザー
                            </th>
                        </tr>
                        </thead>
                        {/* Body */}
                        <tbody>
                        {Object.keys(groupedData).length > 0 ? (
                            Object.keys(groupedData).map((date, dateIndex) => {
                                const rows = groupedData[date];
                                return rows.map((row, rowIndex) => (
                                    <tr key={row.deviceId} className="bg-white hover:bg-gray-50">
                                        {/* Hiển thị `date` chỉ ở dòng đầu tiên của nhóm */}
                                        {rowIndex === 0 ? (
                                            <td rowSpan={rows.length} className="border px-4 py-2">
                                                {date}
                                            </td>
                                        ) : null}
                                        <td className="border px-4 py-2">{rowIndex + 1}</td>
                                        <td className="border px-4 py-2">{row.field}</td>
                                        <td className="border px-4 py-2">{row.oldValue}</td>
                                        <td className="border px-4 py-2">{row.newValue}</td>
                                        <td className="border px-4 py-2">{row.username}</td>
                                    </tr>
                                ));
                            })
                        ) : (
                            <tr>
                                <td
                                    colSpan={6}
                                    className="border px-4 py-8 text-center text-gray-500"
                                >
                                    データがありません
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

export default HistoryTableComponent;

